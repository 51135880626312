
import {Component, Vue} from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import {CustomFormType, ServiceRecordType} from "@/api/appService";

@Component({
  components: {
    PagedTableView,
  }
})
export default class InternalProjectBeneficiaryServiceRecordList extends Vue {
  dataId!: string;
  beneficiaryId!: string;
  type!: string;
  types = ServiceRecordType;


  created() {
    if (this.$route.query.beneficiaryId) {
      this.beneficiaryId = String(this.$route.query.beneficiaryId);
    }

    if (this.$route.query.type) {
      this.type = String(this.$route.query.type);
    }
  }

  fetchData(params: any) {
    if (this.$route.query.beneficiaryId) {
      params.beneficiaryId = Number(this.$route.query.beneficiaryId);
    }
    if (this.$route.query.type) {
      params.serviceRecordType = this.type = String(
        this.$route.query.type
      );
    }
    return api.beneficiaryServiceRecord.getAll(params);
  }

  handleDetail(row: any) {
    const hostType = this.type === ServiceRecordType.Beneficiary ? CustomFormType.BeneficiaryServiceRecord : CustomFormType.SchoolServiceRecord;
    api.customFormService.getCustomFormId({hostType: hostType, hostId: String(row.projectId)}).then((res) => {
      console.log("res="+JSON.stringify(res))
      this.$router.push({
        name: "internalProjectSchoolServiceRecordDetail",
        query: {
          id: row.id,
          formId: res,
          type: this.type
        }
      });
    })
  }

  cancel() {
    this.$router.back();
  }
}
